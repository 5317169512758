<template>
  <a-row class="content-fix org">
    <a-row class="content-title" type="flex">
      <h2>Statistika</h2>
      <a-button
        style="margin-left: auto"
        type="primary"
        @click="modalVisible = true"
      >
        <a-icon type="plus" />{{ $t("Add") }}
      </a-button>
    </a-row>
    <a-row type="flex" class="table__filtrs">
      <a-button
        :disabled="cardsIsEmpty"
        type="primary"
        style="margin: 0 0 15px auto"
        @click="removeItem(selectedPosts)"
      >
        <a-icon class="action-btns" type="delete" />
        {{ $t("Delete") }}
      </a-button>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="list"
      :loading="loading"
      :pagination="{ defaultPageSize: 10 }"
      :row-key="() => Math.random().toString().slice(2)"
      class="faqs"
    >
      <template slot="id" slot-scope="item">
        <a-checkbox
          :checked="selectedPosts.includes(+item.id)"
          :value="+item.id"
          @change="toggleItemId(item.id)"
        />
      </template>
      <template slot="title" slot-scope="item">
        <span>{{ item.title && item.title[$i18n.locale] }}</span>
      </template>
      <template slot="all" slot-scope="item">
        <span>{{ item.all }}</span>
      </template>
      <template slot="solved" slot-scope="item">
        <span>{{ item.solved }}</span>
      </template>
      <template slot="rejected" slot-scope="item">
        <span>{{ item.rejected }}</span>
      </template>
      <template slot="in_review" slot-scope="item">
        <span>{{ item.in_review }}</span>
      </template>
      <template slot="created_at" slot-scope="item">
        <span>{{ item.created_at }}</span>
      </template>
      <template slot="operation" slot-scope="item">
        <a-button type="primary" class="edit-btn" @click="edit(item)">
          <!-- {{ $t("Edit") }} -->
          <a-icon type="edit" class="action-btns" />
        </a-button>
        <a-popconfirm
          :cancel-text="$t('No')"
          :ok-text="$t('Yes')"
          :title="$t('Apopconfirm')"
          @confirm="removeItem(item.id)"
        >
          <a-button type="primary" class="remove-btn">
            <a-icon type="delete" class="action-btns" />
          </a-button>
        </a-popconfirm>
      </template>
    </a-table>
    <a-modal
      :visible="modalVisible"
      :title="$t('Add')"
      :closable="false"
      style="max-height: 500px; max-width: 900px"
      width="800px"
    >
      <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
        <a-col :span="6" style="text-align: right; padding-right: 8px">
          Язык:
        </a-col>
        <a-col :span="16">
          <a-row align="middle" class="flex-baseline flex-wrap">
            <lang-tabs v-model="langTab" class="mb-1x" />
          </a-row>
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          Sarlavha:
        </a-col>
        <a-col :span="16" class="sm-mb">
          <a-input v-model="form.title[getKey]" placeholder="Kiriting" />
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          Jami:
        </a-col>
        <a-col :span="16" class="sm-mb">
          <a-input
            v-model.number="form.all"
            placeholder="Введите"
            spellcheck="false"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          Hal etilgan:
        </a-col>
        <a-col :span="16" class="sm-mb">
          <a-input
            v-model.number="form.solved"
            placeholder="Введите"
            spellcheck="false"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          Rad etilgan:
        </a-col>
        <a-col :span="16" class="sm-mb">
          <a-input
            v-model.number="form.rejected"
            placeholder="Введите"
            spellcheck="false"
          />
        </a-col>
      </a-row>
      <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          Ko'rib chiqilmoqda:
        </a-col>
        <a-col :span="16" class="sm-mb">
          <a-input
            v-model.number="form.in_review"
            placeholder="Введите"
            spellcheck="false"
          />
        </a-col>
      </a-row>
      <!-- <a-row>
        <a-col
          :span="6"
          class="form_title"
          style="text-align: right; padding-right: 8px"
        >
          {{ $t("Status") }}:
        </a-col>
        <a-col :span="12" class="sm-mb">
          <a-switch v-model="form.is_active" />
        </a-col>
      </a-row> -->
      <template slot="footer">
        <a-button key="back" @click="close()"> {{ $t("Close") }} </a-button>
        <a-button type="primary" @click="save">{{ $t("Save") }}</a-button>
      </template>
    </a-modal>
  </a-row>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          key: "id",
          scopedSlots: { customRender: "id" },
          width: "50px",
        },
        {
          title: "Sarlavha",
          key: "title",
          scopedSlots: { customRender: "title" },
          className: "font-md",
        },
        {
          title: "Hal etilgan",
          key: "solved",
          scopedSlots: { customRender: "solved" },
          className: "font-md",
        },
        {
          title: "Rad etilgan",
          key: "rejected",
          scopedSlots: { customRender: "rejected" },
          className: "font-md",
        },
        {
          title: "Ko'rib chiqilmoqda",
          key: "in_review",
          scopedSlots: { customRender: "in_review" },
          className: "font-md",
        },
        {
          title: "Sanasi",
          key: "created_at",
          scopedSlots: { customRender: "created_at" },
          className: "font-md",
        },
        {
          title: "Действие",
          key: "operation",
          align: "right",
          scopedSlots: { customRender: "operation" },
          width: "20%",
        },
      ],
      selectedRowKeys: [],
      selectedPosts: [],
      cardsIsEmpty: true,
      modalVisible: false,
      list: [],
      langTab: $langPrefix,
      form: {
        title: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        all: null,
        solved: null,
        rejected: null,
        in_review: null,
      },
    };
  },
  computed: {
    getKey() {
      return this.langTab;
    },
  },
  watch: {
    $route: {
      handler: "fetchData",
      immediate: true,
    },
  },
  methods: {
    toggleItemId(itemId) {
      const f = this.selectedPosts.findIndex((item) => +item == +itemId);
      if (f != -1) {
        this.selectedPosts.splice(f, 1);
        this.cardsIsEmpty = false;
      } else {
        this.selectedPosts.push(itemId);
        this.cardsIsEmpty = false;
      }
      if (this.selectedPosts.length == 0) {
        this.cardsIsEmpty = true;
      }
    },
    async fetchData() {
      this.loading = true;
      try {
        let res = await this.$api.get(`/admin/statistic/list`);
        const { data } = res.data;
        this.list = data.results;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      this.loading = false;
    },
    async removeItem(id) {
      if (Array.isArray(id)) {
        for (let i of id) {
          try {
            const { status } = await this.$api.delete(`/admin/statistic/${i}`);
            if (status) {
              this.$message.success("Успешно удаленно");
              this.fetchData();
            }
          } catch (e) {
            this.$sentry.captureMessage(e);
          }
        }
      } else {
        try {
          const { status } = await this.$api.delete(`/admin/statistic/${id}`);
          if (status) {
            this.$message.success("Успешно удаленно");
            this.fetchData();
          }
        } catch (e) {
          this.$sentry.captureMessage(e);
        }
      }
      this.selectedCards = [];
      this.cardsIsEmpty = true;
    },
    async save() {
      if (this.form.id) {
        await this.$api.put(`/admin/statistic/${this.form.id}`, this.form);
      } else {
        await this.$api.post("/admin/statistic/create", this.form);
      }
      this.modalVisible = false;
      await this.fetchData();
      this.close();
    },
    async edit(value) {
      this.form.id = value.id;
      this.form.all = value.all;
      this.form.solved = value.solved;
      this.form.rejected = value.rejected;
      this.form.in_review = value.in_review;
      this.form.title = value.title || { uz: "", oz: "", ru: "", en: "" };
      this.modalVisible = true;
    },
    close() {
      this.form = {
        title: {
          uz: "",
          oz: "",
          ru: "",
          en: "",
        },
        all: null,
        solved: null,
        rejected: null,
        in_review: null,
      };
      this.modalVisible = false;
    },
  },
};
</script>